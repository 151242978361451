import React from 'react'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './styles.scss'
import {useHistory} from 'react-router-dom'

const AccountDeleted: React.FC = () => {
    const history = useHistory()
    setTimeout(() => {history.push('/')}, 2000)

    return <div className="wrapper">
        <div>
            <img className="h-auto w-full" src={logo} alt="AvoApp"/>
        </div>
        <div className="info-box">
            Contul tău Advocatus a fost șters cu succes.
        </div>
    </div>
}

export default AccountDeleted