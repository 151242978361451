import React, {useMemo, useState} from 'react'
import {TrashIcon} from '@heroicons/react/24/outline'

// @ts-ignore
import {Button} from '../../components/Button'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {useMutationResourceDelete} from '../../mutations/rest'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {useQueryResourceSearch} from '../../queries/rest'

interface DeleteProfileConfirmProps {
    selectedEntityID: string
    user: any
}

const DeleteProfileConfirm: React.FC<DeleteProfileConfirmProps> = ({selectedEntityID, user}) => {
    const [checkBoxIsChecked, setCheckBoxIsChecked] = useState<boolean>(false)

    const mutation = useMutationResourceDelete(
        RESOURCES.users
    )
    const history = useHistory()

    const {data: entityProfiles} = useQueryResourceSearch(
        RESOURCES.entityProfiles,
        ''
    )
    const selectedEntity = useMemo(() => {
        const _entityProfiles = entityProfiles as any[]
        return _entityProfiles?.find((item: any) => item.id === selectedEntityID)
    }, [entityProfiles, selectedEntityID])

    const isLastAdmin = useMemo(() => {
        return selectedEntity?.is_company_owner && selectedEntity?.is_last_admin
    }, [selectedEntity?.is_company_owner, selectedEntity?.is_last_admin])

    return <div className="page-info">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-5">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Stergere cont</h3>
            </div>
            <div className="px-4 py-5 sm:p-6 flex flex-col gap-5">
                <p>
                    Ești pe cale să ștergi contul tău Advocatus.
                </p>
                <p>
                    Odată șters acest cont, datele tale vor fi șterse.<br/>
                    Ca rezultat, vei pierde accesul la următoarele aplicații:
                    <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                        <li>Monitor Dosare</li>
                        <li>Monitor BPI</li>
                        {user.has_avoapp_access && <li>AvoApp</li>}
                    </ul>
                </p>
                {isLastAdmin && <p>
                    În plus, având în vedere că ești ultimul administrator al contului de
                    companie <b>{selectedEntity.full_name}</b>, acesta va fi, de asemenea, șters
                </p>}
                <div className="py-5">
                    <label>
                        <input
                            type='checkbox'
                            id='terms'
                            onChange={(event) => {
                                setCheckBoxIsChecked(event.target.checked)
                            }}
                            className='mr-3'
                        />
                        Sunt de acord cu ștergerea permanentă a contului meu Advocauts
                        {isLastAdmin && ', precum și a contului de companie aferent.'}
                    </label>
                </div>
                <div className="flex flex-wrap w-full justify-center">
                    <Button
                        title='Șterge'
                        onClick={async () => {
                            await mutation.mutateAsync('me')
                            history.push('/account-deleted')
                        }}
                        loading={mutation.isPending}
                        disabled={!checkBoxIsChecked}
                        icon={() => <TrashIcon className='h-5 w-5 mr-2'/>}
                        color='red'
                    />
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    user: state.users.user
})

export default connect(mapStateToProps)(DeleteProfileConfirm)
